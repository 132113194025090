
export function resampleAudio(inputSamples: Float32Array, inputSampleRate: number, outputSampleRate: number) {
    const inputLength = inputSamples.length;
    const outputLength = Math.ceil(inputLength * outputSampleRate / inputSampleRate);
    const outputSamples = new Float32Array(outputLength);

    const sampleRatio = inputSampleRate / outputSampleRate;

    for (let i = 0; i < outputLength; i++) {
        const index = i * sampleRatio;
        const lowerIndex = Math.floor(index);
        const upperIndex = Math.min(lowerIndex + 1, inputLength - 1);
        const weight = index - lowerIndex;

        outputSamples[i] = inputSamples[lowerIndex] * (1 - weight) + inputSamples[upperIndex] * weight;
    }

    return outputSamples;
}
