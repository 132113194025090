import BezierEasing from 'bezier-easing';
import { clamp, noop } from 'lodash-es';

export function reveal(ctx: CanvasRenderingContext2D, delta: number) {
    let scale = Math.min(1.0, Math.max(0.0, delta));

    scale *= 1.4;
    if (scale > 1.2) scale = scale - (scale - 1.0);
    ctx.scale(scale, scale);
    ctx.globalAlpha = Math.min(1.0, Math.max(0.0, delta));
}

export function fadeIn(ctx: CanvasRenderingContext2D, delta: number) {
    ctx.globalAlpha = Math.min(1.0, Math.max(0.0, delta));
}

export function slideRight(ctx: CanvasRenderingContext2D, delta: number) {
    ctx.globalAlpha = Math.min(1.0, Math.max(0.0, delta));
    const deltaX = 1.0 - Math.min(1.0, Math.max(0.0, delta));
    return { xOffset: -deltaX * 50 };
}

export function slideTop(ctx: CanvasRenderingContext2D, delta: number) {
    const deltaY = 1.0 - Math.min(1.0, Math.max(0.0, delta));
    ctx.globalAlpha = Math.min(1.0, Math.max(0.0, delta));
    return { yOffset: deltaY * 20 };
}


const interpolate = (a: number, b: number, progress: number) => {
    return a + (progress * (b-a));
}

export function shrink(ctx: CanvasRenderingContext2D, delta: number) {

    delta = clamp(delta, 0.0, 1.0);
    const nDelta = 1.0 - delta;
    const scale = interpolate(1.0, 1.5, nDelta);

    ctx.scale(scale, scale);
    ctx.globalAlpha = Math.min(1.0, Math.max(0.0, delta * 1.4));
}




const easing = BezierEasing(0.61, 1, 0.88, 1);

const animations = {
    'reveal': reveal,
    'fade-in': fadeIn,
    'slide-right': slideRight,
    'slide-top': slideTop,
    'shrink': shrink,
}


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default (name: string) => (ctx: CanvasRenderingContext2D, delta: number) => animations[name]?.(ctx, easing(delta) ?? noop)
