import MP4Decoder from "./decoder/mp4-decoder";
import type { Area } from "./types";
import { parseGIF, decompressFrames } from 'gifuct-js'


interface frame {
    bitmap: ImageBitmap;
    timestamp: number;
    duration: number;

    dims?: {
        width: number;
        height: number;
        top: number;
        left: number;
    }
}

export default class GifRenderer {
    url: string;
    live: boolean;
    ctx: CanvasRenderingContext2D;
    canvas: HTMLCanvasElement;

    videoPreview?: HTMLVideoElement;
    decoder?: MP4Decoder;

    prevTimestamp: number;

    frames: frame[];
    duration: number;
    fallback: ImageBitmap;


    width?: number;
    height?: number;
    
    constructor(ctx: CanvasRenderingContext2D, url: string, fallback: ImageBitmap, live: boolean) {
        this.ctx = ctx;
        this.canvas = ctx.canvas;
        this.url = url;
        this.live = live;
        this.prevTimestamp = -1;
        this.frames = [];
        this.duration = 0;
        this.fallback = fallback;

        if (this.live) {
            this.initialize();
        }
    }

    async initialize() {

        const url = 'https://wsrv.nl/?output=gif&n=-1&url=' + this.url;
        const buffer = await (await fetch(url)).arrayBuffer();
        const gif = parseGIF(buffer);

        if (gif.frames.length === 0) {
            this.frames.push({
               bitmap: this.fallback,
               timestamp: 0,
               duration: Infinity
            });
            this.duration = 1;
            return;
        }

        this.width = gif.lsd.width;
        this.height = gif.lsd.height;

        const frames = decompressFrames(gif, true)


        let timestamp = 0;
        for (const frame of frames) {
            const bitmap = await createImageBitmap(new ImageData(frame.patch, frame.dims.width, frame.dims.height))
            this.frames.push({
                bitmap,
                timestamp: timestamp * 1e3,
                duration: frame.delay * 1e3,
                dims: frame.dims,
            })

            timestamp += frame.delay;
            this.duration = timestamp * 1e3;
        }
        
    }

    render(timestamp: number, startMs: number, endMs: number, area: Area) {

        if (timestamp > endMs * 1e3 || timestamp < startMs * 1e3) return;
        const stickerTime = (timestamp - startMs * 1e3) % this.duration;

        const frame = this.frames.find(frame => frame.timestamp <= stickerTime && frame.timestamp + frame.duration >= stickerTime);

        if (frame) {
            if (this.width && this.height && frame.dims) {
                this.ctx.drawImage(frame.bitmap, -frame.dims.left, -frame.dims.top, this.width, this.height, area.x * this.canvas.width, area.y * this.canvas.height, area.width * this.canvas.width, area.height * this.canvas.height);
            }
            else {
                this.ctx.drawImage(frame.bitmap, area.x * this.canvas.width, area.y * this.canvas.height, area.width * this.canvas.width, area.height * this.canvas.height);
            }
        }
    }
}