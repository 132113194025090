let startTime : number | null = null;


export function drawLoader(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, x: number, y: number, width: number, height: number) {
    const timestamp = performance.now();
    if (!startTime) startTime = timestamp;

    const elapsed = timestamp - startTime;
    const angle = (elapsed / 200) * Math.PI / 2;  // rotate 90 degrees per second

    const lineWidth = canvas.width / 80;

    const radius = Math.min(width, height) / 2;
    if (radius <= 0) return;

    ctx.beginPath();
    ctx.arc(x, y, radius - lineWidth, angle, angle + 3 * Math.PI / 2);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = '#2588eb';
    ctx.stroke();

}