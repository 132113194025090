import { noop } from "lodash-es";
import MP4Box, { DataStream } from "mp4box";

// Wraps an MP4Box File as a WritableStream underlying sink.
class MP4FileSink {
  #setStatus = null;
  #file = null;
  #offset = 0;
  #onFinish = null;

  constructor(file, setStatus, onFinish) {
    this.#file = file;
    this.#setStatus = setStatus;
    this.#onFinish = onFinish;
  }

  reset() {
    this.#offset = 0;
  }

  write(chunk) {
    // MP4Box.js requires buffers to be ArrayBuffers, but we have a Uint8Array.
    const buffer = new ArrayBuffer(chunk.byteLength);
    new Uint8Array(buffer).set(chunk);

    // Inform MP4Box where in the file this chunk is from.
    buffer.fileStart = this.#offset;
    this.#offset += buffer.byteLength;

    // Append chunk.
    // this.#setStatus("fetch", (this.#offset / (1024 ** 2)).toFixed(1) + " MiB");
    this.#file.appendBuffer(buffer);
  }

  async close() {
    this.#setStatus("fetch", "Done");
    await this.#file.flush();
    this.#onFinish();
  }
}

// Demuxes the first video track of an MP4 file using MP4Box, calling
// `onConfig()` and `onChunk()` with appropriate WebCodecs objects.
export class MP4Demuxer {
  #onConfig = null;
  #onChunk = null;
  #setStatus = null;
  #file = null;
  processQueue = [];
  onFinishProgressing = null;
  videoSamplesNr = -1;
  audioSamplesNr = -1;
  clipTimeStamps = [];

  videoComplete = false;
  audioComplete = false;

  processingPromise = null;

  stopFeeding = false;

  videoDownloadComplete = false;
  finishedCalled = false;
  demuxFinish = noop;

  fps = 0;

  processSamplesResolve = () => {};

  constructor(uri, {onConfig, onChunk, setStatus, onFinish, startTime, endTime, onFinishProgressing, clipTimeStamps, onError }) {
    this.#onConfig = onConfig;
    this.#onChunk = onChunk;
    this.#setStatus = setStatus;
    this.startTime = startTime;
    this.endTime = endTime;
    this.uri = uri;
    this.needsKeyFrame = true;
    this.clipTimeStamps = clipTimeStamps;

    this.demuxFinish = onFinish;
    this.onFinishProgressing = onFinishProgressing;

    // Configure an MP4Box File for demuxing.
    // eslint-disable-next-line no-undef
    this.#file = MP4Box.createFile();
    this.#file.onError = e => onError(e.message);
    this.onError = onError;
    this.#file.onReady = this.#onReady.bind(this);
    this.#file.onSamples = this.#onSamples.bind(this);
    this.processingQueueIx = 0;
    this.downloadComplete = false;
    this.stopReadingHeader = false;
    this.totalSize = 0;

    this.orientation = null;


    // Fetch the file and pipe the data through.
    this.fileSink = new MP4FileSink(this.#file, setStatus, onFinish);
    this.writer = new WritableStream(this.fileSink, {highWaterMark: 2}).getWriter();

    this.minByteOffset = 0;

    this.fetchHeader()
  }


  fetchHeader() {
    fetch(this.uri).then(async response => {
      // highWaterMark should be large enough for smooth streaming, but lower is
      // better for memory usage.
      // response.body.pipeTo(new WritableStream(fileSink, {highWaterMark: 2}));

      const reader = response.body.getReader();

      let receivedBytes = 0;
      this.totalSize = response.headers.get("content-length");
      let lastLogPercentage = -1;

      while (!this.downloadComplete) {
        if (this.stopReadingHeader && lastLogPercentage < 50) {
          // postMessage({ type: 'progress', progress: 1.0, status: '(1/2) Downloading source..' });
          this.writer.close();
          reader.cancel();
          console.log('found header before end of file');
          break;
        }

        
        const { value, done } = await reader.read();

        receivedBytes += value?.byteLength ?? 0;
        // this.minByteOffset = receivedBytes;

        if (done) {
          this.videoDownloadComplete = true;
          console.log('done reading whole file');
          if (!this.stopReadingHeader) {
            console.warn('Whole video file has been fetched, but header not found yet');
          }
          break;
        }

        const percentage = Math.round(receivedBytes / this.totalSize * 100);
        if (percentage !== lastLogPercentage) {
          lastLogPercentage = percentage;
          postMessage({ type: 'download-progress', progress: percentage });
          console.log(`download=${percentage}`)
        }
  
        // postMessage({ type: 'progress', progress: receivedBytes / this.totalSize, status: '(1/2) Downloading source..' });

        await this.writer.write(value);
      }
    }).catch(error => {
      postMessage({type: 'error', log: error.message });
      console.error(error);
    });
  }

  processQueueEmpty() {
     return this.processQueue[this.processQueue.length - 1] == null;
  }

  seek(time) {
    this.#file.seek(time);
    this.#file.start()
  }

  start() {
    this.#file.start()
  }

  async flush() {
    await this.#file.flush();
  }

  // Get the appropriate `description` for a specific track. Assumes that the
  // track is H.264, H.265, VP8, VP9, or AV1.
  #description(track) {
    const trak = this.#file.getTrackById(track.id);
    for (const entry of trak.mdia.minf.stbl.stsd.entries) {
      const box = entry.avcC || entry.hvcC || entry.vpcC || entry.av1C;
      if (box) {
        const stream = new DataStream(undefined, 0, DataStream.BIG_ENDIAN);
        box.write(stream);
        return new Uint8Array(stream.buffer, 8);  // Remove the box header.
      }
    }
    if (this.onError) this.onError("avcC, hvcC, vpcC, or av1C box not found");
    throw new Error("avcC, hvcC, vpcC, or av1C box not found");
  }

  async #onReady(info) {
    this.#setStatus("demux", "Ready");
    const videoTrack = info.videoTracks[0];
    const audioTrack = info.audioTracks[0];

    const soundHandler = info.tracks.find(track => track.name.endsWith('SoundHandler'));
    if (!audioTrack && soundHandler) {
      console.error(soundHandler)
      if (this.onError) this.onError('Audio track not supported yet');
      throw new Error('Audio track not supported yet')
    }

    this.videoTrack = videoTrack;
    this.audioTrack = audioTrack;


    this.fps = this.videoTrack.nb_samples / (this.videoTrack.duration / this.videoTrack.timescale);


    // Start demuxing.
    this.#file.setExtractionOptions(videoTrack.id, null, { nbSamples: 200 });

    if (audioTrack) {
      this.#file.setExtractionOptions(audioTrack.id, null, { nbSamples: 200 });
    }

    // Generate and emit an appropriate VideoDecoderConfig.
    this.#onConfig({
      // Browser doesn't support parsing full vp8 codec (eg: `vp08.00.41.08`),
      // they only support `vp8`.
      videoConfig: {
        codec: videoTrack.codec.startsWith('vp08') ? 'vp8' : videoTrack.codec,
        codedHeight: videoTrack.video.height,
        codedWidth: videoTrack.video.width,
        description: this.#description(videoTrack),
        id: videoTrack.id,
        hardwareAcceleration: 'prefer-hardware',
      },
      audioConfig: audioTrack ? {
        codec: audioTrack.codec,
        sampleRate: audioTrack.timescale,
        numberOfChannels: audioTrack.audio.channel_count,
        id: audioTrack.id,
      } : null
    });
  }

  async downloadVideoPart() {

    // this.releaseUsedSamples();

    // const bufferBeforeSeconds = 12.0;
    // const actualStartTime = this.startTime - bufferBeforeSeconds;
    const actualStartTime = 0; // start demuxing at the start of the file, some videos like dont work otherways

    // const videoMoov = this.#file.moov.boxes[this.videoTrack.id];
    const videoMoov = this.#file.moov.boxes.find(box => box.samples?.[0].track_id === this.videoTrack.id);

    this.orientation = getVideoOrientation(videoMoov.boxes?.find(box => box.type === 'tkhd')?.matrix);


    let media_time = 0;
    for (const entry of videoMoov?.edts?.elst?.entries ?? []) {

      // transcoding the audio with AudioContext fixes this issue
      if (entry.media_time === -1 && videoMoov?.edts?.elst?.entries.length > 1 && !this.transcodeAudio) {
        const message = 'Video edits not supported yet';
        this.onError(message);
        throw new Error(message);
      }
      else if (entry.media_time) {
        media_time = entry.media_time;
      }
    }

    if (media_time) {
      this.media_time = media_time / this.videoTrack.timescale;
    }


    let audioMoov = null;
    if (this.audioTrack) {
      // audioMoov = this.#file.moov.boxes[this.audioTrack.id];
      audioMoov = this.#file.moov.boxes.find(box => box.samples?.[0]?.track_id === this.audioTrack.id);
    }


    let audio_media_time = 0;
    for (const entry of audioMoov?.edts?.elst?.entries ?? []) {
      if (entry.media_time) {
        audio_media_time = entry.media_time;
      } 
    }

    if (audio_media_time) {
      this.audio_media_time = audio_media_time / this.audioTrack.timescale;
    }

    if (!audioMoov) {
      this.audioComplete = true;
    }

    let endSampleAudio = null;
    let endSampleVideo = null;

    let startOffset = Infinity;
    let endOffset = 0;
    let startTime = Infinity;

    let videoSize = 0;
    let audioSize = 0;

    for (const sample of videoMoov.samples) {
      const timestamp = 1e6 * sample.cts / sample.timescale;
      const duration = 1e6 * sample.duration / sample.timescale;

      sample.timestamp = timestamp;
      // sample.duration = duration;

      if (timestamp + duration >= (actualStartTime) * 1e6 && 
          timestamp <= this.endTime * 1e6
      ) {
        startOffset = Math.min(startOffset, sample.offset);
        endOffset = Math.max(endOffset, sample.offset + sample.size);

        startTime = Math.min(startTime, timestamp);

        if (!endSampleVideo || endSampleVideo.timestamp < sample.timestamp) {
          endSampleVideo = sample;
        }

        videoSize += sample.size;

      }
    }

    if (startOffset == Infinity || startTime == Infinity) {
      this.onError('Error with video timestamps');
    }

    if (audioMoov) {
      for (const sample of audioMoov.samples) {
        const timestamp = 1e6 * sample.cts / sample.timescale;
        const duration = 1e6 * sample.duration / sample.timescale;

        sample.timestamp = timestamp;
        // sample.duration = duration;

        if (timestamp + duration >= (actualStartTime) * 1e6 &&
            timestamp <= this.endTime * 1e6
        ) {
          startOffset = Math.min(startOffset, sample.offset);
          endOffset = Math.max(endOffset, sample.offset + sample.size);

          startTime = Math.min(startTime, timestamp);

          if (!endSampleAudio || endSampleAudio.timestamp < sample.timestamp) {
            endSampleAudio = sample;
          }

          audioSize += sample.size;
        }
      }
    }

    this.endSampleVideo = endSampleVideo;
    this.endSampleAudio = endSampleAudio;


    postMessage({ type: 'progress', progress: 0.0, status: '(2/2) Exporting..' });

    let reader = null;
    let fetchOffset = 0;


    startOffset = Math.max(this.minByteOffset, startOffset);

    let totalSize = 0;
    const getVideoPartReader = async() => {
      const response = await fetch(this.uri, {
        method: 'GET',
        headers: {
          'Range': `bytes=${startOffset + fetchOffset}-`,
        }
      }).catch(e => {
        console.log('Range header bytes values', { startOffset, fetchOffset });
        console.log(e);
        throw e;
      });
      totalSize = response.headers.get("content-length");
      reader = response.body.getReader();
    }

    await getVideoPartReader();

    // this.#file.seek(Math.max(0.0, startTime / 1e6));
    // this.#file.seek(0.0);
    
    let complete = false;
    let lastLogPercentage = -1;

    while(!complete && !this.videoDownloadComplete) {

      const { value, done } = await reader.read();

      complete = done;
      if (done)  {

        if (this.processingPromise) {
          await this.processingPromise;
        }
          console.log('Done fetching video')
          if (!this.finishedCalled) {
            this.finishedCalled = true;
            this.demuxFinish();
            this.onFinishProgressing();
          }
          break;
      }

      const buffer = new ArrayBuffer(value.byteLength);
      new Uint8Array(buffer).set(value);
  

      buffer.fileStart = startOffset + fetchOffset;
      fetchOffset += buffer.byteLength;


      const percentage = Math.round(fetchOffset / totalSize * 100);
      if (percentage !== lastLogPercentage) {
        lastLogPercentage = percentage;
        postMessage({ type: 'download-progress', progress: percentage });
        console.log(`download=${percentage}`)
      }

      await this.#file.appendBuffer(buffer);

      if (this.processingPromise) {
        await this.processingPromise;
      }

      if (this.stopFeeding) {
        console.log('STOP       --------------')
        complete = true;
        reader.cancel();
      }
      // postMessage({ type: 'progress', progress: 1.0, status: '(2/2) Exporting..' });
    }
    
    // postMessage({ type: 'progress', progress: 1.0, status: '(2/2) Exporting..' });

    if (this.processingPromise) {
      await this.processingPromise;
    }

    const videoDurationDifference = this.endTime - this.previousVideoTimeStamp / 1e6;

    // if the download and all processing is complete, but the output video has a duration difference of 4 seconds, throw an error and try again with transcoding the video in ffmpeg
    if (!this.videoComplete && videoDurationDifference >= 4) {
      const errorMessage = 'The video download and decode process has completed successfully, but the duration of the video does not match the expected duration.';
      if (this.onError) this.onError(errorMessage);
      throw new Error(errorMessage)
    }
  

    this.#file.flush();
    this.downloadComplete = true;
    console.log('flush complete')



    if (!this.finishedCalled) {
      this.finishedCalled = true;
      this.processingPromise.then(() => {
        this.demuxFinish();
        this.onFinishProgressing();
      });
    }

    this.dispose();
  }

  dispose(){
    console.log('dispose demuxer ----------')
    for (const box of this.#file.moov.boxes) {
      if (!box.samples || !Array.isArray(box.samples)) continue;
      for (const sample of box.samples) {
        sample.data = null;
      }
    }
  }


  async processSamplesAsync() {
    if (this.isProccessing) return;
    this.isProccessing = true;

    while (this.processQueue[this.processingQueueIx]) {
      const { track_id, samples } = this.processQueue[this.processingQueueIx];
      for (const sample of samples) {      
        const timestamp = 1e6 * sample.cts / sample.timescale;
        const duration = 1e6 * sample.duration / sample.timescale;

        sample.timestamp = timestamp;
        // sample.duration = duration;

        if (track_id === this.videoTrack.id) {
          this.videoSamplesNr = sample.number;

          if (!this.previousVideoTimeStamp) {
              this.previousVideoTimeStamp = timestamp;
          }
          this.previousVideoTimeStamp = timestamp;

          if (!this.videoComplete && (timestamp + sample.duration) >= this.endTime * 1e6) {
            this.videoComplete = true;
          }

        }
        else if (track_id === this.audioTrack?.id) {
          this.audioSamplesNr = sample.number;

          if (!this.audioComplete && (timestamp + sample.duration) >= this.endTime * 1e6) {
            this.audioComplete = true;
          }
        }

        // skip all the first audio frames and video untill first keyframe (always video frame 0)
        // if (this.needsKeyFrame && track_id !== this.audioTrack?.id) {
        //   if (!sample.is_sync) {
        //     sample.data = null;
        //     sample.alreadyRead = 0;
        //     continue;
        //   }
        // }

        
        if (!sample.data) {
          // console.warn('no sample data');
          continue;
        }

        // eslint-disable-next-line no-undef
        await this.#onChunk(sample, timestamp, duration, track_id);

        if (this.needsKeyFrame) {
          this.needsKeyFrame = false;
        }

        sample.data = null;
        sample.alreadyRead = 0;
      }
      // clear up memory
      this.processQueue[this.processingQueueIx].samples = null;
      this.processQueue[this.processingQueueIx].track_id = null;
      this.processQueue[this.processingQueueIx].ref = null;
      this.processQueue[this.processingQueueIx] = null;
      this.processingQueueIx++;

      if (this.processQueue[this.processingQueueIx]) {
        // this.isProccessing = false;
        // this.processingPromise = this.processSamplesAsync(this.processingQueueIx)
      }
      else if (this.audioComplete && this.videoComplete) {
        this.isProccessing = false;
        if (!this.finishedCalled) {
          this.finishedCalled = true;
          this.demuxFinish();
          this.onFinishProgressing();
        }
      }
      else {
        this.isProccessing = false;
      }
    }

    this.processSamplesResolve();
  }

  #onSamples(track_id, ref, samples) {
    this.processQueue.push({ track_id, ref, samples });
    // if processing is done, reschedule it again
    if (!this.processQueue[this.processQueue.length - 2]) {
      this.processingPromise = new Promise(resolve => {
        this.processSamplesResolve = resolve;
      })
      this.processSamplesAsync();
    }

    // we need the first keyframe
    if (track_id === this.videoTrack.id && !this.stopReadingHeader) {
      this.stopReadingHeader = true;
      console.log('start!!!');
      this.downloadVideoPart().catch(error => {
          this.onError(error.message);
          console.error(error);
          throw new Error(error);
      });
    }
  }

  releaseUsedSamples() {
    console.log('releasing', this);
    this.#file.releaseUsedSamples(this.videoTrack.id, this.videoSamplesNr + 1);
    if (this.audioTrack) this.#file.releaseUsedSamples(this.audioTrack.id, this.audioSamplesNr + 1);
    console.log('releasing done', this);
  }
}


function getVideoOrientation(matrix) {
  // Ensure matrix is a valid Int32Array of length 9
  if (!matrix || matrix.length !== 9 || !(matrix instanceof Int32Array)) {
      return null;
  }

  const [a, b, , c, d] = matrix;  // Extract the relevant elements from the matrix
  const aNormalized = a / 65536;
  const bNormalized = b / 65536;
  const cNormalized = c / 65536;
  const dNormalized = d / 65536;

  // Determine the orientation based on the values
  if (aNormalized === 1 && bNormalized === 0 && cNormalized === 0 && dNormalized === 1) {
      return 0; // 0 degrees (normal)
  } else if (aNormalized === 0 && bNormalized === 1 && cNormalized === -1 && dNormalized === 0) {
      return 90; // 90 degrees clockwise
  } else if (aNormalized === -1 && bNormalized === 0 && cNormalized === 0 && dNormalized === -1) {
      return 180; // 180 degrees
  } else if (aNormalized === 0 && bNormalized === -1 && cNormalized === 1 && dNormalized === 0) {
      return 270; // 270 degrees clockwise
  } else {
      return null;
  }
}