let canvas_out = null;

let program = null;
let texture = null;
let gl = null;

const frag_shader_source = /* glsl */ `#version 300 es

    precision mediump float;
    in vec2 v_tpos;

    uniform sampler2D u_texture;
    uniform vec2 u_size;
    out vec4 fragColor;

    void main(){
        // fragColor = blur( u_texture, v_tpos, 1. / u_size);
    
        vec4 texCol = texture(u_texture, v_tpos);
    
        
        int radius = 7;
        float pi = 3.1415926;
        float sigma = 5.;
        
        vec4 gaussSum = vec4(0.);
        
        for(int x = -radius; x <= radius; x++){
            for(int y = -radius; y <= radius; y++){
                vec2 newUV = ((v_tpos * u_size) + vec2(x,y))/u_size.xy;
                vec4 newTexCol = texture(u_texture, newUV);
                gaussSum += texture(u_texture, newUV) * (exp(-(pow(float(x), 2.) + pow(float(y), 2.)) / (2. * pow(sigma, 2.))) / (2. * pi * pow(sigma, 2.)));
            }   
        }
        
        fragColor = vec4(gaussSum);

    }
`

const vtx_shader_source = /* glsl */ `#version 300 es
    in vec2 a_vpos;
    in vec2 a_tpos;
    out vec2 v_tpos;
    
    void main(void) {
        v_tpos = vec2(a_tpos.x, 1.0 - a_tpos.y);
        gl_Position = vec4(a_vpos, 0.0, 1.0);
    }
`



export function drawBlurShader(canvas_in, w, h) {

    if (!canvas_out) {
        canvas_out = new OffscreenCanvas(w, h);
        gl = canvas_out.getContext("webgl2");
        
        // const ctx = canvas_in.getContext("2d");
        gl.viewport(0, 0, w, h);

        const vtx_shader = gl.createShader(gl.VERTEX_SHADER);
        const frag_shader = gl.createShader(gl.FRAGMENT_SHADER);

        gl.shaderSource(vtx_shader, vtx_shader_source);
        gl.shaderSource(frag_shader, frag_shader_source);
        gl.compileShader(vtx_shader);
        gl.compileShader(frag_shader);

        if (!gl.getShaderParameter(vtx_shader, gl.COMPILE_STATUS)){
            console.log(gl.getShaderInfoLog(vtx_shader));
        }
        if (!gl.getShaderParameter(frag_shader, gl.COMPILE_STATUS)){
            console.log(gl.getShaderInfoLog(frag_shader));
        }
        
        program = gl.createProgram();
        gl.attachShader(program, vtx_shader);
        gl.attachShader(program, frag_shader);
        gl.linkProgram(program);

        if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
            // there was an error
            console.error(gl.getProgramInfoLog(program));
        }

        gl.useProgram(program);

        const a_vpos = gl.getAttribLocation(program, "a_vpos");   // vertex coordinate
        const a_tpos = gl.getAttribLocation(program, "a_tpos");   // texture coordinate
        const u_size = gl.getUniformLocation(program, "u_size");  // size of the texture
        const u_texture = gl.getUniformLocation(program, "u_texture"); // texture id, use 0 for TEXTURE0

        gl.enableVertexAttribArray(a_vpos);
        gl.enableVertexAttribArray(a_tpos);
        gl.uniform2fv(u_size, new Float32Array([w, h]));
        gl.uniform1i(u_texture, 0);
        
        const vpos_buf = gl.createBuffer();
        const tpos_buf = gl.createBuffer();
        const idx_buf = gl.createBuffer();
        
        gl.bindBuffer(gl.ARRAY_BUFFER, vpos_buf);
        // order: Bottom-Left, Bottom-Right, Top-Right, Top-Left
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1,-1, 1,-1, 1,1, -1,1]), gl.STATIC_DRAW);
        gl.vertexAttribPointer(a_vpos, 2, gl.FLOAT, false, 0, 0);
        
        gl.bindBuffer(gl.ARRAY_BUFFER, tpos_buf);
        // corresponding texture coordinate
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([0,0, 1,0, 1,1, 0,1]), gl.STATIC_DRAW);
        gl.vertexAttribPointer(a_tpos, 2, gl.FLOAT, false, 0, 0);
        
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, idx_buf);
        // We will draw the square as triangle strip
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array([0,1,3,2]), gl.STATIC_DRAW);
    }

    if (!texture) {
        texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, texture);

        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE );
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE );
    }

    gl.activeTexture(gl.TEXTURE0);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, canvas_in);
    gl.drawElements(gl.TRIANGLE_STRIP, 4, gl.UNSIGNED_SHORT, 0);


    return canvas_out;

}
