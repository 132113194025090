import type { Sticker } from '@/areas/editor/@type/Project'
import type { Sticker as OldSticker } from '@/store/entity-system/useStickersStore'
import { retryAsync } from '@/helpers/retry'
import * as Sentry from '@sentry/browser'

const stickerCache: { key: string, arrayBuffer: ArrayBuffer }[] = [];


export async function downloadStickerScreenshot(sticker: OldSticker | Sticker, scale: number, quality: number, signal?: AbortSignal) {

    const cacheKey = JSON.stringify({
        quality: quality,
        key: sticker.key,
        textContent: 'textContent' in sticker ? sticker.textContent : null,
        color: 'color' in sticker ? sticker.color : null,
        icon: 'icon' in sticker ? sticker.icon : null,
    });

    const fromCache = stickerCache.find(item => item.key === cacheKey);
    if (fromCache) {
        return fromCache.arrayBuffer;
    }

    const image = await retryAsync(async () => {
      const response = await fetch(import.meta.env.VITE_UNIVERSE_SCREENSHOT_URL, { 
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          renderData: {
            sticker: { ...sticker, scale: scale },
            renderWidth: quality,
            renderHeight: quality * (16 / 9),
          },
          url: 'https://app.streamladder.com/screenshot-page',
        }),
        signal,
      });
      if (!response.ok) {
        console.error(JSON.stringify({
          renderData: {
            sticker: { ...sticker, scale: scale },
            renderWidth: quality,
            renderHeight: quality * (16 / 9),
          },
          url: 'https://app.streamladder.com/screenshot-page',
        }));
        console.error('Response:', response);
        Sentry.captureException(new Error(`Sticker screenshot failed: ${response.statusText}`));
        throw new Error(response.statusText);
      }
      return response;
    }, { maxRetries: Infinity })

    if (image.ok) {
      const arrayBuffer = await image.arrayBuffer();

      while (stickerCache.length > 10) {
        stickerCache.shift();
      }

      stickerCache.push({ key: cacheKey, arrayBuffer });
      return arrayBuffer;
    } else {
      throw new Error('Failed to download image');
    }
}
