import captionAnimations from './caption-animations'
import { merge, omit } from 'lodash-es'
import { PunctuationRegexp } from '@/data/PunctuationRegexp'

const fontSizes = {
    'large': 40,
    'medium': 26,
    'small': 20,
}

export class CaptionBase {
    
    // to cache the breaks and dont recalculate it each render again
    static linesBreakMap = {};
    static currentCaption = null;

    measureTextCache = {};

    constructor(canvas, ctx) {
        this.canvas = canvas;
        this.ctx = ctx;
        this.options = {};
    }

    get canvasScale() {
        return this.canvas.height / 1080;
    }

    get captionOptions() {
        return this.options?.captionStylesSettings ?? {};
    }

    get drawBackground() {
        return this.captionOptions?.background?.opacity > 0;
    }

    setFillStyleWithGradient(fillStyle, x0, y0, x1, y1) {
        const { ctx, options } = this;

        if (!fillStyle.colorStops) {
            ctx.fillStyle = fillStyle;
            return;
        }

        if (fillStyle.variant === "linear") {
            let angleRadians = fillStyle.angle * (Math.PI / 180);

            const height = y1 - y0;
            y0 -= height;
            y1 -= height;

            let dx = x1 - x0;
            let dy = y1 - y0;
            const length = Math.sqrt(dx * dx + dy * dy); 

            const xOffset = Math.cos(angleRadians) * length
            const yOffset = Math.sin(angleRadians) * length

            let _x1 = x0 + xOffset;
            let _y1 = y0 + yOffset;

            const gradient = ctx.createLinearGradient(x0, y0, Math.min(_x1, x1), Math.min(_y1, y1));
            for (const { stop, color } of fillStyle.colorStops) {    
                gradient.addColorStop(stop, color);
            }
            ctx.fillStyle = gradient;
        } else if (fillStyle.variant === "radial") {
            const height = y1 - y0;
            y0 -= height;
            y1 -= height;

            const gradient = ctx.createRadialGradient(x0 + x1 / 2, y0 + y1 / 2, 1, x0 + x1 / 2, y0 + y1 / 2, (x1 - x0) / 2);
            for (const { stop, color } of fillStyle.colorStops) {    
                gradient.addColorStop(stop, color);
            }
            ctx.fillStyle = gradient;
        } else {
            ctx.fillStyle = fillStyle;
        }
    }


    drawTextBackground(options, line, x, y) {
        const { ctx } = this;
        const { fontSize, fontFamily } = this.getRenderOptions();
        ctx.font = `${fontSize}px ${fontFamily}`;

        const metrics = this.measureText(line.join(' '));
        const lineWidth = metrics.width;

        if (this.options.isSticker) {
            const stickerContainer = this.getStickerContainerSize();
            x += stickerContainer.width / 2;
            // y += stickerContainer.height / 2;
            y += fontSize;
        }

        const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
        const fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
        const fontCenter = y - actualHeight / 2;
        
        const padding = 22 * this.canvasScale;

        const renderWidth = lineWidth + padding * 2;
        const renderHeight = actualHeight + padding * 2;
        const borderRadius = 8;

        const oldAlpha = ctx.globalAlpha;
        ctx.globalAlpha = this.captionOptions?.background.opacity;
        ctx.fillStyle = this.captionOptions.background?.color;
        // this.setFillStyleWithGradient(this.captionOptions.background?.color, x - renderWidth / 2, fontCenter - renderHeight / 2, renderWidth, renderHeight);
        ctx.beginPath();
        ctx.roundRect(x - renderWidth / 2, fontCenter - renderHeight / 2, renderWidth, renderHeight, this.captionOptions?.background?.radius * this.canvasScale);
        ctx.fill();
        ctx.globalAlpha = oldAlpha;
    }

    drawActiveWordBackground(options, lines, x, y, fillStyle, activeWordIx) {
        const { ctx } = this;
        let line = '';
        let currentWords = [];

        for (const words of lines) {
            if (words[activeWordIx]) {
                line = words.join(' ');
                currentWords = words;
                break;
            }
            activeWordIx -= words.length;
        }

        y *= lines.indexOf(currentWords);
        
        const metrics = this.measureText(line);
        const lineWidth = metrics.width;
        
        const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
        const fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
        const fontCenter = y - actualHeight / 2;

        
        const padding = 22 * this.canvasScale;

        const renderWidth = lineWidth + padding * 2;
        const renderHeight = actualHeight + padding * 2;

        
        const borderRadius = 8;

        if (activeWordIx > -1) {
            const words = currentWords;

            const highLightStyle = this.captionOptions.background.highlightColor;

            if (words[activeWordIx]) {
                const leftWords = words.slice(0, activeWordIx);
                if (activeWordIx !== words.length - 1 && leftWords.length > 0) leftWords.push(' ');

                let left = this.measureText(leftWords.join(' ')).width;
                let width = this.measureText(words[activeWordIx]).width;

                if (words.length === 1) {
                    width += padding * 2;
                }
                else if (activeWordIx === 0) {
                    width += padding * 2;
                }
                else if (activeWordIx === words.length - 1) {
                    left += padding * 1;
                    width += padding * 2;
                }
                else {
                    left -= padding * 1;
                    width += padding * 2;
                }
                

                ctx.fillStyle = highLightStyle;
                ctx.beginPath();
                ctx.roundRect(x - renderWidth / 2 + left, fontCenter - renderHeight / 2, width, renderHeight, borderRadius);
                ctx.fill();
            }
        }
    }

    measureText(text) {
        const cacheKey = text + this.ctx.font;
        const fromCache = this.measureTextCache[cacheKey];

        if (fromCache) return fromCache;

        const metrics = this.ctx.measureText(text);

        this.measureTextCache[cacheKey] = metrics;
        
        return metrics;
    }
    
    drawColoredSentence(timestamp, words, x, y, fillStyle, activeWordIx = -1, fontSize, totalWords = -1, skipActiveWord=false) {

        const { ctx, options } = this;

        const fontFamily = this.getRenderOptions().fontFamily;
        ctx.font = `${fontSize}px ${fontFamily}`;

        const line = words.join(' ');
        const metrics = this.measureText(line);
        const lineWidth = metrics.width;

        if (this.options.isSticker) {
            const stickerContainer = this.getStickerContainerSize();
            x += stickerContainer.width / 2;
            // y += stickerContainer.height / 2;
            y += fontSize;
        }

        let currentX = x;
        for (let ix = 0; ix < words.length; ix++) {
            const isHighlighted = activeWordIx === ix;

            let word = words[ix];
            if (ix !== words.length - 1) word += ' ';

            let wordIx = ix + totalWords;
            if (CaptionBase.currentCaption.hasEmojiTop) {
                wordIx -= 1;
            }
            const captionWord = CaptionBase.currentCaption?.words?.[wordIx];
            const variant = captionWord?.color ? this.captionOptions.variants.find(variant => variant.key === captionWord.color) : {};
            const captionStyle = merge({}, omit(this.captionOptions, 'variants'), variant);

            ctx.font = `${fontSize}px ${fontFamily}`
            let x = currentX - lineWidth / 2;

            const {width} = this.measureText(word);
            ctx.shadowBlur = 0;
            ctx.shadowColor = '';

            if (((activeWordIx > -1 || totalWords > -1) && !isHighlighted && !skipActiveWord) || (skipActiveWord && isHighlighted)) {
                currentX += width;
                continue;
            }

            const textFillStyle = isHighlighted
              ? captionStyle.font.highlightColor
              : captionStyle.font.color;
            
            if (activeWordIx === ix && !this.drawBackground) {

                // const activeWordGrow = 3 * (this.canvas.height / 1080);
                const activeWordGrow = 0;

                ctx.font = `${fontSize + activeWordGrow}px ${fontFamily}`
                x -= activeWordGrow;
            }

            let animationOffsets = {};
            if (captionWord && options.baseOptions.animationTarget === 'word') {
                ctx.globalAlpha = 1.0;
                ctx.scale(1.0, 1.0);
                const animationDuration = Math.min(.2, (captionWord.end - captionWord.start) * .5 / 1e3);
                const wordTimeMs = (timestamp / 1_000 - captionWord.start) / 1_000;

                const animation = this.options.baseOptions.animation;
                const animationFunction = captionAnimations(animation);
                animationOffsets = animationFunction && animationFunction(ctx, wordTimeMs / animationDuration);
            }

            const drawText = () => {
                let currentY = y;
                let currentX = x;

                if (options.baseOptions.animationTarget === 'word' && totalWords > -1) {
                    if (animationOffsets?.xOffset) currentX += animationOffsets.xOffset * this.canvasScale;
                    if (animationOffsets?.yOffset) currentY += animationOffsets.yOffset * this.canvasScale;
                }

                // stroke text
                if (captionStyle?.stroke?.width > 0) {
                    ctx.miterLimit = 2;

                    ctx.lineWidth = captionStyle.stroke.width * fontSize / 50;

                    ctx.strokeStyle = isHighlighted ? captionStyle.stroke.highlightColor : captionStyle.stroke.color;
                    ctx.strokeText(word, currentX, currentY);
                }

                this.setFillStyleWithGradient(textFillStyle, currentX, currentY, currentX + width, currentY + fontSize);
                ctx.fillText(word, currentX, currentY);

                // Reset
                ctx.shadowOffsetX = 0
                ctx.shadowOffsetY = 0
                ctx.shadowBlur = 0
                ctx.strokeStyle = '';
                ctx.lineWidth = 0;
            }

            if (captionStyle?.glow?.radius > 0) {
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
                ctx.shadowBlur = this.canvasScale * captionStyle.glow?.radius;

                ctx.shadowColor = isHighlighted ? captionStyle.glow.highlightColor : captionStyle.glow.color;
                drawText();
            }

            if (captionStyle?.shadow?.opacity > 0) {
                ctx.shadowOffsetX = captionStyle.shadow.offsetX * this.canvasScale;
                ctx.shadowOffsetY = captionStyle.shadow.offsetY * this.canvasScale;
                ctx.shadowBlur = captionStyle.shadow.blur * this.canvasScale;

                ctx.shadowColor = isHighlighted ? captionStyle.shadow.highlightColor : captionStyle.shadow.color;
                drawText();
            }

            drawText();

            currentX += width;
        }
    
        ctx.font = `${fontSize}px ${fontFamily}`
        ctx.shadowBlur = 0;
        ctx.shadowColor = '';
        return words.length;
    }

    getRenderOptions(lines = 0) {
        // magic numbers from captionContainer
        const containerHeight = this.canvas.height;
        const containerWidth = this.canvas.width;

        // const containerHeight = 568;
        // const containerWidth = 320;

        const wrapperHeight = 150;
        const wrapperWidth = 220;
    
        if (this.options?.captionSettings?.area) {
            const lines = this.options.captionSettings.textContent.split('\n').length;

            const fontSize = Math.round((this.captionOptions.font?.fontSize) * this.canvasScale);
            const lineMargin = 2 * this.captionOptions.font.leading * this.canvasScale;
            const fontSizeScale = (this.options?.captionSettings?.area.height * this.canvas.height - lineMargin * lines) / (lines * (fontSize + lineMargin));

            return {
                fontSize: fontSize * fontSizeScale,
                fontFamily: `"${this.captionOptions.font?.fontFamily}", "AppleColorEmoji"`,
                x: (this.canvas.width * this.options.captionSettings.area.x ?? .5), //+ (!this.options.isSticker ? (scale * wrapperWidth / 2) : 0), // * (this.canvasScale + .5),
                y: (this.canvas.height * this.options.captionSettings.area.y ?? .7), //+ (!this.options.isSticker ? (scale * wrapperHeight / 2) : 0), // * (this.canvasScale + .5),
            }
        }

        const scale = this.options?.captionSettings?.scale * containerWidth;
        // idk why but for the fontsize this scale is needed
        const fontSizeScale = this.options?.captionSettings?.scale * 320;

        const fontSize = fontSizes[this.options.baseOptions.size] ?? this.captionOptions.font?.fontSize;

        const lineMargin = 2 * this.captionOptions.font.leading * this.canvasScale;
        const renderFontSize = Math.round((fontSize) * this.canvasScale) * fontSizeScale * (this.options.isSticker ? 1.3 : 1.4);
        
        return {
            fontSize: renderFontSize,
            fontFamily: `"${this.captionOptions.font?.fontFamily}", "AppleColorEmoji"`,
            x: (this.canvas.width * this.options?.captionSettings?.x ?? .5) + (!this.options.isSticker ? (scale * wrapperWidth / 2) : 0), // * (this.canvasScale + .5),
            y: (this.canvas.height * this.options?.captionSettings?.y ?? .7) + (!this.options.isSticker ? (scale * wrapperHeight / 2) : 0) + renderFontSize - .5 * (lines * renderFontSize + (lines - 1) * lineMargin), // * (this.canvasScale + .5),
        }
    }

    getLineBreaks(options, text, addEmoji, fontSize = null) {
        const { ctx, canvas } = this;

        const margin = 32 * (canvas.height / 1080);
        const renderOptions = this.getRenderOptions();

        if (!fontSize) {
            fontSize = renderOptions.fontSize;
        }

        let width = 0;
        ctx.font = `${fontSize}px ${renderOptions.fontFamily}`

        const cacheKey = text.join(' ');

        // dont cache in this preview
        if (!CaptionBase.linesBreakMap[cacheKey] || options.live) {
            const lines = [];
            const boundingRects = [];
            const words = text;
            let currentLine = [];

            const wrapperWidth = 220;
            // const maxWidth = canvas.width - margin * 2;
            const maxWidth = this.options?.captionSettings?.scale * canvas.width * wrapperWidth;

            while (words.length > 0) {

                while (width < maxWidth && words.length > 0) {
                    currentLine.push(words.shift());
                    width = this.measureText(currentLine.join(' ')).width

                    if (width > maxWidth) {

                        // single word does not fit on the screen
                        if (currentLine.length === 1) {
                            const halfIx = Math.floor(currentLine[0].length / 2);
                            words.unshift(currentLine[0].substring(0, halfIx) + '-', currentLine[0].substring(halfIx));
                            currentLine = [];
                        } else {
                            words.unshift(currentLine.pop());
                            break;
                        }
                    }
                }

                if (currentLine.length) {
                    const sentence = currentLine;
                    lines.push(sentence)
                    boundingRects.push(this.measureText(sentence.join(' ')));
                }
                currentLine = [];
                width = 0;
            }


            CaptionBase.currentCaption.hasEmojiTop = false;
            if (addEmoji && CaptionBase.currentCaption?.emojis?.length > 0) {
                
                const emoji = CaptionBase.currentCaption.emojis[0];
                if (this.options.baseOptions.emojiLocation === 'top') {
                    lines.unshift([emoji])
                    CaptionBase.currentCaption.hasEmojiTop = true;
                }
                else {
                    lines.push([emoji]);
                }
            }

            CaptionBase.linesBreakMap[cacheKey] = { lines, boundingRects };
        }
        return CaptionBase.linesBreakMap[cacheKey];
    }

    getCurrentCaptionWithLineBreaks(options, timestamp) {
        if (!options?.captions?.length) {
            return { lines: [] };
        }


        CaptionBase.currentCaption = null;
        // dont cache in this preview
        if (!CaptionBase.currentCaption || CaptionBase.currentCaption.start * 1_000 > timestamp || CaptionBase.currentCaption.end * 1_000 < timestamp || options.live) {
            // console.log('current transcript invalidated');
            for (const transcript of options.captions) {
                if (timestamp >= transcript.start * 1_000 && timestamp <= transcript.end * 1_000) {
                     // console.log(transcript)
                    CaptionBase.currentCaption = transcript;
                    if (options.baseOptions.rotate) {
                        if (!CaptionBase.currentCaption.rotation) CaptionBase.currentCaption.rotation = Math.random() * 2.0 - 1.0;
                    }
                    else {
                        CaptionBase.currentCaption.rotation = 0.0;
                    }
                }
            }
        }

        if (CaptionBase.currentCaption) {

            if (CaptionBase.currentCaption.lines) {
                return { lines: CaptionBase.currentCaption.lines };
            }

            let line = [];

            for (const word of CaptionBase.currentCaption.words) {
                line.push(word);
            }

            if (options.baseOptions.stripPunctuation) {
                line = line.map((word) => ({
                    ...word,
                    text: word.editedByUser ? word.text : word.text.replace(PunctuationRegexp, '')
                }));
            }

            line = line.map(word => word.text);

            if (this.captionOptions.font.textTransform === 'uppercase') {
                line = line.map(word => word.toUpperCase());
            }
            else if (this.captionOptions.font.textTransform === 'lowercase') {
                line = line.map(word => word.toLowerCase());
            }
            else if (this.captionOptions.font.textTransform === 'capitalize') {
                if (line.length) {
                    line[0] = line[0].charAt(0).toUpperCase() + line[0].slice(1);
                }
            }

            if (line.length === 0) {
                return { lines: [] };
            }

            return this.getLineBreaks(options, line, options.baseOptions.emojis);
        }

        return { lines: [] };
    }

    getActiveWordIx(timestamp) {

        if (!this.options.baseOptions.highlight) return -1;
        if (this.options.baseOptions.grouping === 'single') return -1;

        let activeWordIx = -1;
        let ixOffset = 0;
        
        for (let ix = 0; ix < CaptionBase.currentCaption.words.length; ix++) {
            // apply offset for words that are not in the clip for the active word index
            if (CaptionBase.currentCaption.words[ix].outSideClip) {
                ixOffset++;
                continue;
            }
            
            if (CaptionBase.currentCaption.words[ix].start <= timestamp / 1_000 && CaptionBase.currentCaption.words[ix].end >= timestamp / 1_000) {
                activeWordIx = ix - ixOffset;
                break;
            }
        }

        if (CaptionBase.currentCaption.hasEmojiTop && activeWordIx > -1) {
            activeWordIx += 1;
        }

        return activeWordIx;
    }

    getStickerContainerSize() {
        const { ctx, canvas } = this;
        const { fontSize, fontFamily } = this.getRenderOptions();
        const lines = CaptionBase.currentCaption.lines;
        let width = 0;
        let height = 0;
        for (const line of lines) {
            ctx.font = `${fontSize}px ${fontFamily}`;
            const metrics = this.measureText(line);
            width = Math.max(metrics.width, width);
            const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
            height += actualHeight;
        }

        const lineMargin = 2 * this.captionOptions.font.leading * (canvas.height / 1080);
        height += lineMargin * (lines.length - 1);


        return { width, height }
    }

    render(options, timestamp) {
        this.options = options;
        const { ctx, canvas } = this;

        const { lines } = this.getCurrentCaptionWithLineBreaks(options, timestamp);
        const { fontSize, x, y } = this.getRenderOptions(lines.length);


        let fillStyle = options.mainColorIsOutlineColor ? 'white' : options.gradient ?? this.captionOptions.font?.color ?? options?.captionStyleDefinition?.colors?.[0] ?? 'white';
        const lineMargin = 2 * this.captionOptions.font?.leading * (canvas.height / 1080);

        ctx.globalAlpha = 1.0;
        
        // first 4 seconds show the shortHook
        if (options?.clipInfo?.shortHook && (timestamp / 1_000) - options?.clipInfo?.start < 4_000) {
            const shortHookFontSize = fontSizes.small * (this.canvas.height / 1080);
            ctx.textAlign = 'left';
            ctx.strokeStyle = 'black';
            ctx.lineJoin = 'round';
            ctx.miterLimit = 2;
            ctx.lineWidth = shortHookFontSize / 4.0;

            const shortHookLines = this.getLineBreaks(options, options?.clipInfo?.shortHook, false, shortHookFontSize)?.lines;

            let shortHookFillStyle = options.mainColorIsOutlineColor ? 'white' : options.gradient ?? options?.captionStyleDefinition?.colors?.[0] ?? 'white';
            if (this.drawBackground) {
                shortHookFillStyle = 'white'
            }

            if (this.drawBackground) {
                for (let ix = 0; ix < shortHookLines.length; ix++) {
                    this.drawTextBackground(options, shortHookLines[ix], canvas.width / 2, 100 * (canvas.height / 1080) + ix * (shortHookFontSize + lineMargin));
                }
            }

            for (let ix = 0; ix < shortHookLines.length; ix++) {
                this.drawColoredSentence(timestamp, shortHookLines[ix], canvas.width / 2, 100 * (canvas.height / 1080) + ix * (shortHookFontSize + lineMargin), shortHookFillStyle, -1, shortHookFontSize);
            }
        }
        
        // dont render if there is no caption.
        if (lines.length === 0) return;

        const activeWordIx = this.getActiveWordIx(timestamp);

        const transcriptTimeS = (timestamp / 1_000 - CaptionBase.currentCaption.start) / 1_000;

        ctx.save();
        ctx.translate(x, y);
        ctx.rotate(Math.PI / 20 * CaptionBase.currentCaption.rotation);

        if (options.baseOptions.animation && options.baseOptions.animationTarget === 'sentence') {

            const animationFunction = captionAnimations(options.baseOptions.animation);
            const animationDuration = Math.min(.15, (CaptionBase.currentCaption.end - CaptionBase.currentCaption.start) * .5 / 1e3);
            const animationOffsets = animationFunction(ctx, transcriptTimeS / animationDuration);
            let translateX = 0;
            let translateY = 0;
            if (animationOffsets?.xOffset) translateX += animationOffsets.xOffset * 5.0;
            if (animationOffsets?.yOffset) translateY += animationOffsets.yOffset * 5.0;

            ctx.translate(translateX * this.canvasScale, translateY * this.canvasScale);
        }

        
        // for (let ix = 0; ix < lines.length; ix++) {
        //     ctx.strokeText(lines[ix], 0, (fontSize + lineMargin) * ix);
        // }


        ctx.textAlign = "left";
        
        if (this.drawBackground) {
            for (let ix = 0; ix < lines.length; ix++) {
                this.drawTextBackground(options, lines[ix], 0, (fontSize + lineMargin) * ix)
            }
            if (activeWordIx > -1) {
                this.drawActiveWordBackground(options, lines, 0, fontSize + lineMargin, fillStyle, activeWordIx);
            }
        }

        let totalWords = 0;
        const drawActiveWord = () => {
            totalWords = 0;
            this.ctx.save()
            // render active word behind others
            for (let ix = 0; ix < lines.length; ix++) {
                totalWords += this.drawColoredSentence(timestamp, lines[ix], 0, (fontSize + lineMargin) * ix, fillStyle, activeWordIx - totalWords, fontSize, totalWords)
            }

            this.ctx.restore()
        }

        
        const drawActiveWordBeforeOtherText = options?.baseOptions?.animation !== 'shrink';

        if (drawActiveWordBeforeOtherText) {
            drawActiveWord();
        }
        else {
            this.ctx.save();
        }


        totalWords = 0;
        for (let ix = 0; ix < lines.length; ix++) {
            totalWords += this.drawColoredSentence(timestamp, lines[ix], 0, (fontSize + lineMargin) * ix, fillStyle, activeWordIx - totalWords, fontSize, totalWords, true)
        }

        if (!drawActiveWordBeforeOtherText) {
            this.ctx.restore()
            drawActiveWord();
        }

        ctx.textAlign = "center";

        ctx.restore();
    }
}
