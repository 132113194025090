export function getBlackAreas(imageData) {
    const { width, height, data } = imageData;
    const visited = new Array(width * height).fill(false);
    const areas = [];

    function isBlack(r, g, b) {
        return r === 0 && g === 0 && b === 0;
    }

    function floodFill(x, y) {
        const stack = [[x, y]];
        let minX = x, maxX = x, minY = y, maxY = y;

        while (stack.length) {
            const [px, py] = stack.pop();
            const index = (py * width + px) * 4;

            if (visited[py * width + px]) continue;
            visited[py * width + px] = true;

            const r = data[index];
            const g = data[index + 1];
            const b = data[index + 2];

            if (!isBlack(r, g, b)) continue;

            minX = Math.min(minX, px);
            maxX = Math.max(maxX, px);
            minY = Math.min(minY, py);
            maxY = Math.max(maxY, py);

            const neighbors = [
                [px - 1, py],
                [px + 1, py],
                [px, py - 1],
                [px, py + 1]
            ];

            for (const [nx, ny] of neighbors) {
                if (nx >= 0 && nx < width && ny >= 0 && ny < height && !visited[ny * width + nx]) {
                    stack.push([nx, ny]);
                }
            }
        }

        const area = {
            x: minX / width,
            y: minY / height,
            width: (maxX - minX + 1) / width,
            height: (maxY - minY + 1) / height
        };

        areas.push(area);
    }

    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            const index = (y * width + x) * 4;
            const r = data[index];
            const g = data[index + 1];
            const b = data[index + 2];

            if (isBlack(r, g, b) && !visited[y * width + x]) {
                floodFill(x, y);
            }
        }
    }

    return areas;
}