import textLibrary, { textStylesDictionary } from '@/components/Stickers/textLibrary'
import { type CaptionStyle, captionStylesSettings } from '@/components/Captions/styles/CaptionStyleManager'
import { captionStyleToCaptionPreset } from '@/components/Captions/v3/captionStyleToCaptionPreset'
import type { CaptionPreset } from '@/components/Captions/v3/CaptionPreset'
import type { FontLabel } from '@/data/fonts'

function isCaptionStyle(key: string): key is CaptionStyle {
  return key in captionStylesSettings
}

function isTextStyle(key: string): key is keyof typeof textStylesDictionary {
  return key in textStylesDictionary
}

export function textStyleToCaptionPreset(key: keyof typeof textStylesDictionary, primaryColor: string, secondaryColor?: string) {
  if (isCaptionStyle(key)) {
    return captionStyleToCaptionPreset(key, primaryColor)
  } else if (isTextStyle(key)) {
    return textLibraryItemToCaptionPreset(key, { primaryColor, secondaryColor })
  } else {
    console.warn(`Unknown text style: ${key}`)
  }
}

function textLibraryItemToCaptionPreset(
  key: keyof typeof textStylesDictionary, 
  overrides: { primaryColor: string, secondaryColor?: string, fontFamily?: FontLabel }
): CaptionPreset {
  
  const textLibraryItem = textStylesDictionary[key]
  const primaryColor = overrides.primaryColor in textLibraryItem.colors 
    ? textLibraryItem.colors[overrides.primaryColor as keyof typeof textLibraryItem['colors']]
    : overrides.primaryColor
  const secondaryColor = overrides.secondaryColor ?? textLibraryItem.colors[primaryColor as keyof typeof textLibraryItem['colors']]

  return {
    key: key,
    createdAt: textLibraryItem.createdAt,
    background: {
      color: secondaryColor,
      highlightColor: secondaryColor,
      radius: 25,
      opacity: opacityOf(textLibraryItem),
      editable: false
    },
    glow: {
      color: primaryColor,
      highlightColor: primaryColor,
      radius: textLibraryItem.variant === 'glow' ? 10 : 0,
      editable: false
    },
    animation: {
      style: 'none',
      speed: 0
    },
    font: {
      fontFamily: overrides.fontFamily ?? fontFamilyOf(textLibraryItem),
      fontWeight: 400,
      fontSize: 16,
      leading: 1.2,
      kerning: 0,
      color: primaryColor,
      highlightColor: primaryColor,
      textTransform: 'none',
      editable: false
    },
    shadow: {
      color: primaryColor,
      highlightColor: primaryColor,
      offsetX: 10,
      offsetY: 10,
      blur: 0,
      opacity: 0,
      editable: false
    },
    stroke: {
      color: secondaryColor,
      highlightColor: secondaryColor,
      width: textLibraryItem.variant === 'outline' ? 2 : 0,
      editable: false
    },
    variants: [],
    tags: []
  }
}

function opacityOf(textLibraryItem: typeof textLibrary[number]) {
  switch (textLibraryItem.variant) {
    case 'padded':
      return 1
    case 'transparent':
      return 0.5
    default:
      return 0
  }
}

function fontFamilyOf(textLibraryItem: typeof textLibrary[number]) {
  switch (textLibraryItem.variant) {
    case 'outline':
      return 'bangers'
    case 'outline-2':
      return 'Luckiest Guy'
    case 'fat-montserrat':
      return 'Montserrat Black'
    default:
      return 'metropolis'
  }
}
